import { Card, CardMedia} from "@mui/material";
import  {motion} from "framer-motion"; 

function ProductImages() {

    return (
        <>       
        <motion.div
          initial={{ opacity: 0, x: 0 }}
          whileInView={{ opacity: 1, x: 100 }}
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <motion.div
              animate={{ y: [0, -5, 0] }}
              transition={{ duration: 2, repeat: Infinity, repeatType: "loop" }}
            >
          <Card
            sx={{
              width: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 20,
               transform: 'rotateX(20deg) rotateY(20deg)'
            }}
          >
            <CardMedia
              component="img"
              image="apm1.png"
              alt="Product Image"
              sx={{ maxHeight: '100%' }}
            />
          </Card>
          </motion.div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 100, y:50 }}
          whileInView={{ opacity: 1, x: -100, y:35 }}
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          
          <Card
            sx={{
              width: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 20,
               transform: 'rotateX(20deg) rotateY(20deg)'
            }}
          >
            <CardMedia
              component="img"
              image="apm2.png"
              alt="Product Image"
              sx={{ maxWidth: '100%' }}
            />
          </Card>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 0, y:-100 }}
          whileInView={{ opacity: 1, x: -100, y:0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <Card
            sx={{
              width: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 20,
               transform: 'rotateX(20deg) rotateY(20deg)'
            }}
          >
            <CardMedia
              component="img"
              image="apm3.png"
              alt="Product Image"
              sx={{ maxWidth: '100%' }}
            />
          </Card>
        </motion.div>
      </>
    );
}
export default ProductImages;