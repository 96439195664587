import React from 'react';
import Lottie from 'react-lottie';
import animationData from './anim_map.json';

export default function Map() {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <div>
        <Lottie 
          options={defaultOptions}
          height={100}
          width={100}
        />
      </div>
    );
  }