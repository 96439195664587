import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

const TwoColumnCard = ({ left_item, right_item }) => {
    return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} minHeight={'80vh'}>
        <Grid display="flex" justifyContent="center" alignItems="center" size={{ xs: 12, md: 6 }}>
          {left_item}
        </Grid>
        <Grid display="flex" justifyContent="center" alignItems="center" size={{ xs: 12, md: 6 }}>
          {right_item}
        </Grid>
      </Grid>
    </Box>
    )
}

export default TwoColumnCard;