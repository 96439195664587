import * as React from "react";
import { Container} from "@mui/material";
import TwoColumnCard from "./twoColumnCard";
import TextAndSubtitle from "./textAndSubtitle";
import { Card, CardMedia } from '@mui/material';
import { motion } from "framer-motion";

function ContactForm() {
    return (
    <>
      <Container id="contact">
        <TwoColumnCard 
        left_item={<TextAndSubtitle subtitle={"We're here 24/7 to help you with any questions you may have. Simply click the chat icon in the bottom right corner to start a conversation."} title={'Start your journey with us and'} highlighted={'get in touch.'}  align={'right'}></TextAndSubtitle>} 
        right_item={
          <motion.div
          initial={{ opacity: 0, y: -100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {/* add the image contact_us.svg here */}
          <Card 
          elevation={0}
          style={{background: 'transparent'}}>
            <CardMedia
              component="img"
              image="contact_us.svg" // Update the path to your image
              alt="Contact Us"
            />
        
          </Card>
        </motion.div>
      }/>
      </Container>
    </>      
    );
}
  
export default ContactForm;