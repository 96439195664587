import { Box } from "@mui/material";
import  {motion} from "framer-motion"; 
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';

import React, { useState } from 'react';




const ServicesCards = ({ items }) => {
  const [animationCompleted, setAnimationCompleted] = useState(Array(items.length).fill(false));

  const handleAnimationStart = (index) => {
    setAnimationCompleted((prev) => {
      const newCompleted = [...prev];
      newCompleted[index] = false;
      return newCompleted;
    });
  };

  const handleAnimationComplete = (index) => {
    setAnimationCompleted((prev) => {
      const newCompleted = [...prev];
      newCompleted[index] = true;
      return newCompleted;
    });
  };

  return (
    <Grid container>
      {items.map((item, index) => (
        <Grid item xs={12} md={6} lg={6} key={index}>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.5, delay: index * 0.05 }}
            onAnimationStart={() => handleAnimationStart(index)}
            onAnimationComplete={() => handleAnimationComplete(index)}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'top',
                textAlign: 'left',
                paddingBottom: 2,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '100px', minHeight: '100px' }}>

              {animationCompleted[index] && (
                  <motion.div
                  initial={{ opacity: 0,  }}
                  whileInView={{ opacity: 1, }}
                  viewport={{ once: false }}
                  transition={{ duration: 0.5 }}
                  >
                    {item.icon}
                  </motion.div>
                )}

              </Box>
              <Box sx={{ marginLeft: 2 }}>
                <Typography variant="h3" component="h3">
                  {item.title}
                </Typography>
                <Typography variant="body1" component="p" sx={{minWidth: '300px'}}>
                  {item.description}
                </Typography>
              </Box>
            </Box>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
};

export default ServicesCards;