
import ResponsiveAppBar from './components/navigation';
import Products from './components/products';
import Services from './components/services';
import Intercom from '@intercom/messenger-js-sdk';
import ContactForm from './components/contactForm';
import "@fontsource/poppins";
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/800.css";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Partnerships from './components/partnerships';


const darkTheme = createTheme({
  palette: {
    primary: {main:'#55B48B'},
    secondary: {main:'#ff4081'},
    mode: 'dark'
  },
  typography: {
    "fontWeight": 200,
    "fontFamily": 'Poppins',
    h1: {
      "fontFamily": 'Poppins',
      "fontWeight": 800,
      "fontSize": 20,
      "color": "primary",
      "paddingTop": 50
    },
    h2: {
      "fontFamily": 'Poppins',
      "fontSize": 40,
      "fontWeight": 800
    },
    h3: {
      "fontSize": 18,
      "fontFamily": 'Poppins',
      "fontWeight": 800
    },
    p: {
      "fontFamily": 'Poppins',
      "fontSize": 16,
      "fontWeight": 200
    }
  }

});

function App() {
  return (
    <>
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <ResponsiveAppBar/>
        <Products />
        <Services />
        <Partnerships/>
        <ContactForm/>
      </div>
    </ThemeProvider>
    <Intercom app_id='l618j4i6'/>
    </>

  );
}

export default App;
