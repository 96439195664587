import ServicesCards from './servicesCards';
import TwoColumnCard from './twoColumnCard';
import TextAndSubtitle from './textAndSubtitle';
import { Container } from '@mui/material';
import Circuits from "./animated_icons/circuits";
import Brain from "./animated_icons/brain";
import Network from "./animated_icons/network";
import WebDesign from "./animated_icons/web_design";
import DataArchitecture from "./animated_icons/data_architecture";
import BarChart from "./animated_icons/bar_chart";
import DataMigration from "./animated_icons/data_migration";
import Map from "./animated_icons/map";

function Services() {

  const items = [
    { icon: <Circuits/>, title: 'Artificial Intelligence', description:'Transform your business with Artificial Intelligence by enhancing decision-making, streamlining operations, and delivering personalized experiences that drive innovation and boost competitiveness.' },
    { icon: <Brain />, title: 'Machine Learning' , description:'Unlock the power of Machine Learning to predict outcomes, optimize processes, and discover hidden insights, propelling your business ahead of the competition.'},
    { icon: <Network/>, title: 'Deep Learning', description:'Harness Deep Learning, a powerful subset of Machine Learning, to emulate human brain functions, process complex data, and create intelligent patterns for superior decision-making.' },
    { icon: <WebDesign/>, title: 'User Experience (UX)', description:'Elevate your product design with User Experience (UX) strategies that create meaningful, relevant, and engaging experiences, ensuring customer satisfaction and loyalty.' },
    { icon: <DataArchitecture />, title: 'Data Architecture' ,description:'Establish a robust Data Architecture framework that sets the standards for data collection, storage, management, and integration, enabling seamless data flow and business efficiency.' },
    { icon: <BarChart/>, title: 'Data Analytics', description:'Empower your decision-making with Data Analytics by turning raw data into actionable insights, driving informed strategies and business success.' },
    { icon: <DataMigration/>, title: 'Data Migration', description:'Simplify your Data Migration process, ensuring seamless transitions between locations, formats, or applications while maintaining data integrity and minimizing downtime.' },
    { icon: <Map />,  title: 'Data Integration', description:'Achieve a unified view of your business operations by combining data from multiple sources through effective Data Integration, enhancing insights and operational efficiency.' },
  ];

    return (
      <>
        <Container id="services">
          <TwoColumnCard  
            left_item={<TextAndSubtitle subtitle={'As experts in both design & development, we help you iterate through the journey from initial concepts, to deployment at scale using bleeding edge technologies.'} title={'Start innovating today with our'} highlighted={'Services.'} ></TextAndSubtitle>}
            right_item={<ServicesCards items={items}/>}
          />
        </Container>
      </>
    );
  }
  export default Services;