import * as React from "react";
import { Container} from "@mui/material";
import TwoColumnCard from "./twoColumnCard";
import TextAndSubtitle from "./textAndSubtitle";
import ProductImages from "./productImages";

function Products() {
    return (
    <>
      <Container id="products">
        <TwoColumnCard 
        left_item={<TextAndSubtitle subtitle={'With over a decade in the Industrial sector we offer a wide range of products from Asset Performance Management, Predictive Maintenance, Condition Monitoring, and more.'} title={'Optimise your business with our'} highlighted={'Products.'} ></TextAndSubtitle>} 
        right_item={<ProductImages/>}/>
      </Container>
    </>      
    );
}
  
export default Products;