import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { motion} from "framer-motion"; 

function TextAndSubtitle({title, highlighted, subtitle}) {

    return (
        <>
            <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: false }}
                transition={{ duration: 1 }}
                style={{  display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Box sx={{ display: 'inline' }}>
                        <Typography 
                            variant="h2" 
                            sx={{
                                display: 'inline',
                                textAlign: 'left'
                            }}>
                        {title}
                        </Typography>
                        <Typography 
                            variant="h2" 
                            sx={{
                                background: 'linear-gradient(90deg, #392A6F, #55B48B)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                display: 'inline',
                                textAlign: 'left',
                                marginLeft: 1 //ADDS A SPACE BEWTEEN THE TWO TEXTS
                            }}>
                        {highlighted}
                        </Typography>
                        <Typography 
                            sx={{
                                textAlign: 'left',
                                paddingTop: 5 
                            }}>
                            {subtitle}
                        </Typography>
                    </Box>
          
            </motion.div>
        </>
    );
}
export default TextAndSubtitle;